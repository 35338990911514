import { Link } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import EmptyFallback from '~/components/render-kit/EmptyFallback'
import RenderWhenVisible from '~/components/render-kit/RenderWhenVisible'
import { AllStrapiArticle } from '~/types/graphql'
import { convertInDateWithoutTime } from '~/utils'

import ArticleImage from './ArticleImage'
import * as containerStyles from './RecentArticles.module.scss'

interface RecentArticlesProps {
  data: AllStrapiArticle
  buttonText?: string
}

const RecentArticles = ({ data, buttonText }: RecentArticlesProps) => (
  <div className={containerStyles.recentPost}>
    <div className="container container-md">
      <RenderWhenVisible
        className="row"
        fallback={<EmptyFallback height={350} className="row" />}
      >
        {data.allStrapiArticle.edges.map(({ node: article }) => {
          const modifiedDate = convertInDateWithoutTime(
            article!.modified_date as string,
          )

          return (
            <div className="col-12 col-md-6 col-lg-4" key={article.url}>
              <div className={containerStyles.recentPost__postCard}>
                <Link
                  title={article.title?.trim() || ''}
                  to={`/blog/${article.url}/`}
                  className={containerStyles.recentPost__postLink}
                />

                <div className={containerStyles.recentPost__postImage}>
                  <ArticleImage
                    image={
                      article.preview_image!
                        .localFile as unknown as IGatsbyImageData
                    }
                    alt={article.preview_image_alt || ''}
                  />
                  <div className={containerStyles.recentPost__postCategory}>
                    {article.category?.name}
                  </div>
                </div>

                <div className={containerStyles.recentPost__postTitle}>
                  {article.title}
                </div>

                <div className={containerStyles.recentPost__postDescriprion}>
                  {article.meta_description}
                </div>

                <div className={containerStyles.recentPost__postInfoWrapper}>
                  {article.authors?.[0]?.avatar?.localFile?.childImageSharp
                    ?.gatsbyImageData && (
                    <div
                      className={containerStyles.recentPost__postAuthorImage}
                    >
                      <GatsbyImage
                        image={
                          article.authors[0].avatar.localFile.childImageSharp
                            .gatsbyImageData as IGatsbyImageData
                        }
                        alt={`${article.authors?.[0]?.name}, ${article.authors?.[0]?.position} at Codica`}
                        title={`${article.authors?.[0]?.name}, ${article.authors?.[0]?.position}`}
                        loading="lazy"
                      />
                    </div>
                  )}

                  <div className={containerStyles.recentPost__postAuthor}>
                    <div className={containerStyles.recentPost__postAuthorName}>
                      {article.authors?.[0]?.name}
                    </div>
                    <div className={containerStyles.recentPost__postInfo}>
                      <div className={containerStyles.recentPost__postDate}>
                        {modifiedDate}
                      </div>
                      <div
                        className={containerStyles.recentPost__postStats}
                      >{`${Math.ceil(
                        article?.body?.data?.childMarkdownRemark?.timeToRead ||
                          17,
                      )} min read`}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}

        {buttonText && (
          <div className="col-12 d-flex justify-content-center mt-4">
            <Link to="/blog/" className={containerStyles.recentPost__button}>
              {buttonText}
            </Link>
          </div>
        )}
      </RenderWhenVisible>
    </div>
  </div>
)

export default RecentArticles
