// extracted by mini-css-extract-plugin
export var socialBlock = "cn_p8";
export var socialBlock__icon = "cn_qf";
export var socialBlock__iconWrapper = "cn_qd";
export var socialBlock__icon_facebook = "cn_qg";
export var socialBlock__icon_instagram = "cn_qk";
export var socialBlock__icon_linkedin = "cn_qh";
export var socialBlock__icon_telegram = "cn_ql";
export var socialBlock__icon_tiktok = "cn_qm";
export var socialBlock__icon_twitter = "cn_qj";
export var socialBlock__iconsWrapper = "cn_qc";
export var socialBlock__sprite = "cn_qb";
export var socialBlock__title = "cn_p9";