// extracted by mini-css-extract-plugin
export var recentPost = "bV_m6";
export var recentPost__button = "bV_nn";
export var recentPost__postAuthor = "bV_nh";
export var recentPost__postAuthorImage = "bV_ng";
export var recentPost__postAuthorName = "bV_nj";
export var recentPost__postCard = "bV_m7";
export var recentPost__postCategory = "bV_nb";
export var recentPost__postDate = "bV_nm";
export var recentPost__postDescriprion = "bV_nd";
export var recentPost__postImage = "bV_m9";
export var recentPost__postInfo = "bV_nk";
export var recentPost__postInfoWrapper = "bV_nf";
export var recentPost__postLink = "bV_m8";
export var recentPost__postStats = "bV_nl";
export var recentPost__postTitle = "bV_nc";
export var recentPost__title = "bV_np";